@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hide scrollbar */
//html {
//    overflow-y: scroll;
//    scrollbar-width: none; /* Firefox */
//    -ms-overflow-style: none; /* Edge */
//    &::-webkit-scrollbar { /* Webkit */
//        display: none;
//    }
//}

.spinning {
  animation: spin infinite 1s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
